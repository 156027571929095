import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LogoutComponent } from '../authenticate/logout/logout.component';
import { GlobalConstants } from '../common/global-constants';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  path: string = '';
  @Input() toggleState: boolean = false;
  @Output() toggler = new EventEmitter<boolean>();
  private isAuth!: Subscription;
  isLoggedIn: boolean = false;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private authService: AuthService,

    private renderer: Renderer2, private elementRef: ElementRef
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.closeNavbar();
      }
    });
  }

  ngOnInit(): void {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.path = this.router.url;
      }
    });
    this.isAuth = this.authService
      .loggedInUpdate()
      .subscribe((response: boolean) => {
        this.isLoggedIn = response;
      });
  }
  @HostListener('window:scroll', ['$event'])
  scrollNav(e:any) {

    let element = document.querySelector('.nav-frame') as HTMLElement;


    if (e.target['scrollingElement'].scrollTop > 80) {

      element.classList.add('cust-back');
    } else {
      element.classList.remove('cust-back');
    }
  }
  @Output() scrollToTop = new EventEmitter<void>();

  onScrollToTop(): void {
    this.scrollToTop.emit();
  }

  resetGlobals(){
    GlobalConstants.isLoading= false;
    GlobalConstants.step = 1;
    GlobalConstants.stepper = 'Create Account';
  }
  fireEvent() {

    console.log('her!');

  }

  @HostListener('document:click', ['$event.target'])
  onDocumentClick(target: HTMLElement): void {
    const navbarContent = this.elementRef.nativeElement.querySelector('#navbarSupportedContent');
    console.log('yes!');

    if (navbarContent && navbarContent.classList.contains('show')) {
      // Check if the click is outside the navbar
      if (!this.elementRef.nativeElement.contains(target)) {
        // Close the navbar
        navbarContent.classList.remove('show');
        navbarContent.setAttribute('aria-expanded', 'false');
      }
    }
  }
  private closeNavbar(): void {
    const navbarContent = this.elementRef.nativeElement.querySelector('#navbarSupportedContent');
    if (navbarContent) {
      navbarContent.classList.remove('show');
      navbarContent.setAttribute('aria-expanded', 'false');
    }
  }

  checkRoute() {
    return this.path.startsWith('/app');
  }

  logout() {
    const dialog = this.dialog.open(LogoutComponent, {
      maxWidth: '550px',
      maxHeight: '450px',
      backdropClass: 'dialogBack',
    });

    dialog.afterClosed().subscribe(() => {});
  }

  ngOnDestroy() {
    if (this.isAuth) {
      this.isAuth.unsubscribe();
    }

  }
}
